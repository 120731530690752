.contact {
    background: #fff; 
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10% auto;
    max-width: 50% ; 
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  
    &-link {
      display: flex;
      align-items: center;
      justify-content: center; 
      text-decoration: none;
      color: #333; 
      font-size: 16px;
      margin: 10px 0; 
  
      &:hover {
        color: #25752d; 
        transition: color 0.3s ease-in-out;
      }
    }
  
    @media (max-width: 768px) {
      .contact {
        padding: 15px;
      }
  
      .contact-link {
        flex-direction: column; 
        margin: 15px 0; 
      }
    }

    &-image {
        margin-right: 1.5rem; 
        width: 20px; 

      }
  }
  