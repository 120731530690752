@use "../../styles/media-queries" as *;


.about{
    margin: 2rem 2rem;
    border-bottom: 1px solid green;


    &-main-title{
        width: 100%;
        padding-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
    }

    &-first{
        text-align: justify;
        font-size: 1.3rem;
    }

    &-second{
        text-align: justify;
        font-size: 1.3rem;

        &-paragraph{
          padding: 2rem;
          text-align: justify;
          font-size: 1.3rem;
          font-weight: 500;

          @include tablet{
            display: flex;
            justify-content: center;
            font-weight: 600;
            font-size: 1.5rem;


          }
        }
    }

    &-title{
        padding-bottom: 2rem;
        font-size: 2.5rem;
        line-height: 2rem;
        font-weight: 500;
    }

    &-story-image{
        width: 22rem;
    }
    
    &-image{
        height: 60vh;
        width: auto;

        @include tablet{
            max-height: 50vh;
            width: auto;

        }
    }

    &-container-first{
        border-top: 1px solid green;
        padding: 2rem 0 ;
        display: flex;
        flex-direction: column;
        @include tablet{
          padding: 2rem 3rem ;

        }

       

    }

    &-container-second{
        display: flex;
        flex-direction: column;
        gap: 3rem;

        @include tablet{
            flex-direction: row;
            justify-content: space-between;

        }
    }

    &-second{
        display: flex;
        align-items: center;
    }
}


h1 {
    font-size:32px;
    text-align:center;
    height: 100%;
    position: relative;
    top:50%;
    overflow:hidden;
    color:#000000;
  }
  
  .intro-text > span {
      height: 100%;
    display: block;
    margin: 0 10%;
    width: 50%;
    position: relative;
    
  }
  
  .intro-text > span > span {
      display: inline-block;
    position: absolute;
    text-shadow: 10px 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .intro-text > span > span:nth-of-type(1) {
      left: 0;
    z-index: 1;
  }
  .intro-text > span > span:nth-of-type(2) {
      left: 120px;
    z-index: 2;
    animation: animate 3s linear alternate infinite;
  }
  .intro-text > span > span:nth-of-type(3) {
      left: 260px;
    z-index: 1;
  }
  .intro-text > span > span:nth-of-type(4) {
      left: 380px;
    z-index: 3;
    animation: animate 3s linear alternate-reverse infinite;
  }
  .intro-text > span > span:nth-of-type(5) {
      left: 520px;
    z-index: 1;
  }
  .intro-text > span > span:nth-of-type(6) {
      left: 625px;
    z-index: 2;
    animation: animate 3s linear alternate infinite;
  }
  
  
  @keyframes animate {
    0%{transform:translateY(10px);}
    100%{transform:translateY(-10px);}
  }

  /* TextAnimation.css */
.intro-text {
    font-size: 2rem;
    font-weight: 550;
    align-items: center;
    justify-content: center;
    display: flex;
    white-space: nowrap; 
    overflow: hidden; 
    padding-bottom: 2rem;


    @include tablet{
        font-size: 4rem;

    }
  }
  