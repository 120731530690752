@use "../../styles/media-queries" as *;


.section{
    margin: 2rem 4rem;

    &-title{
    }

    &-image{
    display: flex;
    justify-content: center;
    width: 100%;

        @include tablet{
       } 
       @include desktop{

       }
       
    }

    &-slider{
        gap: 2rem;
    }
  

}

