@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&family=Varela+Round&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // border: 1px solid pink;
    // font-family: "Source Sans 3", sans-serif;
    font-family: "Varela Round", sans-serif;
    font-family: "Saira Condensed", sans-serif;



}