.footer{
    display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  justify-content: center;
  width: 100%;
    


    &-mail, &-desc {
        font-size: 1.2rem;
        color: rgb(8, 54, 21);
        font-weight:300;
    }
}
