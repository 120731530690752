@use "../../styles/media-queries" as *;


.nav{

    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    border-bottom: 1px solid green;

    &-logo{
        display: flex;
        justify-content: center;
        width: 18rem;
        padding-bottom: 2rem;

        @include tablet{
            width: 20rem;

        }
        @include desktop{
            width: 30rem;

        }
    }

    &-menu{
        display: flex;
        flex-direction: column;

        @include tablet{
            gap: 3rem;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: baseline;

        }
        

    }

    &-container{
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
    }

}


.nav-home-first{
    display: none;

    @include tablet{
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: 500;
        display: flex;
        padding-bottom: 1rem;
        color: black;

    }
}
.nav-home-second{
    text-decoration: none;
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    color: black;



    @include tablet{
        display: none;
    }
}

.nav-us{
        font-size: 1.5rem;
        text-decoration: none;
        color: black;
        font-weight: 500;
}
